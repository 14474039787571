.button {
  @apply py-2 px-4 rounded inline-block;
}

.button-default {
  @apply bg-slate-200;
}

.button-primary {
  @apply bg-apari-purple text-white;
}
